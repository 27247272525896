<template>
  <v-container fluid>
    <v-card flat>
      <v-form lazy-validation v-on:submit.prevent="onSubmit" ref="form">
        <v-card-title>
          <span class="headline primary--text">{{ titulo }}</span>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2"
            ripple
            color="primary"
            icon="icon"
            @click="cancelar"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                name="atalho"
                filled
                rounded
                dense
                required
                :rules="rules.textoObrigatorio"
                v-model.trim="autoTexto.atalho"
                label="Atalho"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <Editor v-model.trim="autoTexto.conteudo" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pr-4">
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="cancelar"> cancelar </v-btn>
          <v-btn type="submit" color="primary"> Gravar </v-btn>
        </v-card-actions>

        <alerta></alerta>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import Alerta from "@/components/comum/Alerta.vue";
import { mapActions, mapGetters } from "vuex";
import Editor from "@/components/comum/Editor/Simple";

export default {
  name: "auto-texto-create-edit",
  data() {
    return {
      autoTexto: {},
      titulo: "Auto Texto",
      rules: {
        campoObrigatorio: [(v) => !!v || "Campo é obrigatório"],
        textoObrigatorio: [
          (v) => !!v || "Campo é obrigatório",
          (v) => (v && !!v.trim()) || "Valor não pode ser branco",
        ],
        emailObrigatorio: [
          (v) => !!v || "E-mail obrigatório",
          (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
        ],
      },
    };
  },
  components: { Alerta, Editor },
  computed: {
    ...mapGetters(["ultimaTela"]),
  },
  methods: {
    ...mapActions(["setAutoTextoQuery", "setAlerta"]),
    loadModel() {
      var self = this;
      if (this.$route.params.id) {
        this.titulo = "Alterar Auto Texto";
        this.$http
          .get(`/autoTexto/${this.$route.params.id}`)
          .then((response) => {
            self.autoTexto = response.data;
          })
          .catch(() => {
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: "Erro ao carregar auto texto",
            });
          });
      }
    },
    onSubmit() {
      var self = this;

      if (!this.$refs.form.validate() || !this.autoTexto.conteudo) {
        self.setAlerta({
          tipoAlerta: "error",
          textAlerta: "Favor preencher todos os campos",
        });
      } else {
        if (this.autoTexto.id) {
          this.$http
            .put("/autoTexto", this.autoTexto)
            .then(() => {
              self.setAutoTextoQuery({ query: self.autoTexto.atalho });
              self.cancelar();
              self.setAlerta({
                tipoAlerta: "success",
                textAlerta: "Auto Texto alterado com sucesso",
              });
            })
            .catch(() => {
              self.setAlerta({
                tipoAlerta: "error",
                textAlerta: "Erro ao alterar auto texto",
              });
            });
        } else {
          this.$http
            .post("/autoTexto", this.autoTexto)
            .then(() => {
              self.setAutoTextoQuery({ query: self.autoTexto.atalho });
              self.cancelar();
              self.setAlerta({
                tipoAlerta: "success",
                textAlerta: "Auto Texto criado com sucesso",
              });
            })
            .catch(() => {
              self.setAlerta({
                tipoAlerta: "error",
                textAlerta: "Erro ao criar auto texto",
              });
            });
        }
      }
    },

    cancelar() {
      if (this.ultimaTela) {
        this.$router.push({ path: this.ultimaTela });
      } else {
        this.$router.go(-1);
      }
    },
  },
  created() {
    this.loadModel();
  },
};
</script>
